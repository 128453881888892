import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  color: white;
  font-family: 'Arial', sans-serif;
`;

const Header = styled.header`
  padding: 2rem;
  text-align: center;
  animation: ${fadeIn} 1s ease-out;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 2rem;
`;

const Content = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
`;

const Feature = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem;
  width: 300px;
  text-align: center;
  animation: ${slideIn} 1s ease-out;
  animation-delay: ${props => props.delay};
`;

const FeatureIcon = styled.span`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
`;

const CTAButton = styled.button`
  background-color: #f7b733;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 2rem;

  &:hover {
    background-color: #f9c74f;
  }
`;

const Footer = styled.footer`
  text-align: center;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.2);
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #f7b733;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f9c74f;
  }
`;

const features = [
  {
    icon: '???',
    title: 'Interactive Map',
    description: 'Explore virtual routes based on famous pet-friendly locations worldwide.'
  },
  {
    icon: '??',
    title: 'Activity Tracking',
    description: 'Log daily walks and playtime with your furry friend using our app.'
  },
  {
    icon: '??',
    title: 'Milestone Rewards',
    description: 'Unlock digital badges and receive exciting pet treats as you progress.'
  },
  {
    icon: '??',
    title: 'Community Challenges',
    description: 'Join teams and compete in global challenges with other pet owners.'
  },
  {
    icon: '??',
    title: 'Pet Health Tips',
    description: 'Access breed-specific tips and local pet-friendly spot information.'
  },
  {
    icon: '??',
    title: 'Personalized Experience',
    description: "Customize your avatar and your pet's for a unique journey."
  }
];

function ComingSoon() {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    const countDownDate = new Date("2024-10-01T00:00:00").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setCountdown({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data.message);
      setFormData({ name: '', email: '', message: '' });
      setShowModal(false);
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error submitting form:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Container>
      <Header>
        <Logo src="/path-to-your-logo.png" alt="Paws on the Go Logo" />
        <Title>Paws on the Go</Title>
        <Subtitle>The Ultimate Adventure for You and Your Furry Friend</Subtitle>
        <div>
          {Object.entries(countdown).map(([unit, value]) => (
            <span key={unit} style={{margin: '0 10px'}}>
              {value} {unit}
            </span>
          ))}
        </div>
      </Header>
      <Content>
        {features.map((feature, index) => (
          <Feature key={index} delay={`${0.2 * index}s`}>
            <FeatureIcon>{feature.icon}</FeatureIcon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </Feature>
        ))}
      </Content>
      <Footer>
        <CTAButton onClick={() => setShowModal(true)}>Join the Waitlist</CTAButton>
        <p style={{marginTop: '1rem'}}>A portion of every subscription supports animal shelters worldwide.</p>
      </Footer>
      {showModal && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
            <Title>Join the Adventure</Title>
            <Form onSubmit={handleSubmit}>
              <Input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextArea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <SubmitButton type="submit">Send</SubmitButton>
            </Form>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
}

export default ComingSoon;