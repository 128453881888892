// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzoKHxT-tBXhCZQVdmURljvbtYvN7qHrU",
  authDomain: "challenge-b4e4e.firebaseapp.com",
  projectId: "challenge-b4e4e",
  storageBucket: "challenge-b4e4e.appspot.com",
  messagingSenderId: "482274526419",
  appId: "1:482274526419:web:a56ea03df5389d51834026",
  measurementId: "G-DSP5JX7T3Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database };