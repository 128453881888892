import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { auth, database } from './firebase';
import { ref, set, onValue } from "firebase/database";

const MAP_WIDTH = 2048;
const MAP_HEIGHT = 1536;
const MAP_CENTER_X = MAP_WIDTH / 2;
const MAP_CENTER_Y = MAP_HEIGHT / 2;
const MILES_TO_EDGE = 25;
const PIXELS_PER_MILE = MAP_WIDTH / (2 * MILES_TO_EDGE);

const MapContainer = styled.div`
  width: ${MAP_WIDTH}px;
  height: ${MAP_HEIGHT}px;
  position: relative;
  overflow: hidden;
`;

const FantasyMapImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarImage = styled.img`
  width: 40px;
  height: auto;
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  transform: translate(-50%, -50%);
`;

const PathCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const ControlButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  background-color: #f7b733;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
`;

const WarningMessage = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 0, 0.7);
  color: black;
  padding: 10px;
  border-radius: 5px;
  z-index: 10;
`;

const FantasyMap = () => {
  const [position, setPosition] = useState({ x: MAP_CENTER_X, y: MAP_CENTER_Y });
  const [path, setPath] = useState([]);
  const mapRef = useRef(null);
  const [startCoords, setStartCoords] = useState(null);
  const [error, setError] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const requestLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Initial position acquired:", position);
          setStartCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setError(null);
        },
        (error) => {
          console.error("Error getting initial location:", error);
          setError(`Error: ${error.message}`);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const successCallback = useCallback((position) => {
    if (!startCoords) return;

    const { latitude, longitude } = position.coords;
    console.log("New position:", latitude, longitude);
    
    const latDiff = (latitude - startCoords.latitude) * 69;
    const lonDiff = (longitude - startCoords.longitude) * 69.172 * Math.cos(startCoords.latitude * Math.PI / 180);
    
    const distance = Math.sqrt(latDiff * latDiff + lonDiff * lonDiff);
    const sensitivityFactor = 1000;
    
    const pixelX = MAP_CENTER_X + (lonDiff * PIXELS_PER_MILE * sensitivityFactor);
    const pixelY = MAP_CENTER_Y - (latDiff * PIXELS_PER_MILE * sensitivityFactor);

    setPosition({ x: pixelX, y: pixelY });
    setPath(prevPath => [...prevPath, { x: pixelX, y: pixelY }]);
    setTotalDistance(prevDistance => prevDistance + distance);

    if (user) {
      set(ref(database, `users/${user.uid}/totalDistance`), totalDistance + distance);
    }
  }, [startCoords, totalDistance, user]);

  useEffect(() => {
    if (!startCoords) return;

    const watchId = navigator.geolocation.watchPosition(
      successCallback,
      (error) => {
        console.error("Error getting location:", error);
        setError(`Ongoing error: ${error.message}`);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, [startCoords, successCallback]);

  useEffect(() => {
    const canvas = document.getElementById('pathCanvas');
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, MAP_WIDTH, MAP_HEIGHT);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 3;
    ctx.beginPath();
    path.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point.x, point.y);
      } else {
        ctx.lineTo(point.x, point.y);
      }
    });
    ctx.stroke();

    localStorage.setItem('fantasyMapPath', JSON.stringify(path));
  }, [path]);

  useEffect(() => {
    const savedPath = localStorage.getItem('fantasyMapPath');
    if (savedPath) {
      setPath(JSON.parse(savedPath));
    }
  }, []);

  useEffect(() => {
    if (user) {
      const distanceRef = ref(database, `users/${user.uid}/totalDistance`);
      onValue(distanceRef, (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          setTotalDistance(data);
        }
      });
    }
  }, [user]);

  const simulateMovement = () => {
    setPosition(prev => ({
      x: prev.x + (Math.random() - 0.5) * 20,
      y: prev.y + (Math.random() - 0.5) * 20
    }));
  };

  return (
    <MapContainer ref={mapRef}>
      <FantasyMapImage src="/shiremap.jpg" alt="Fantasy Map" />
      <PathCanvas id="pathCanvas" width={MAP_WIDTH} height={MAP_HEIGHT} />
      <AvatarImage src="./dog-image.png" alt="Dog Avatar" x={position.x} y={position.y} />
      {error && <div style={{color: 'red', position: 'absolute', top: '10px', left: '10px'}}>{error}</div>}
      {!user && <WarningMessage>Your progress will not be saved unless you log in.</WarningMessage>}
      <ControlButton onClick={requestLocationPermission}>Enable Location</ControlButton>
      <ControlButton onClick={simulateMovement} style={{left: '150px'}}>Simulate Movement</ControlButton>
      <div style={{position: 'absolute', top: '10px', right: '10px', background: 'white', padding: '5px'}}>
        Total Distance: {totalDistance.toFixed(2)} miles
      </div>
    </MapContainer>
  );
};

export default FantasyMap;