import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComingSoon from '../pages/ComingSoon';
import FantasyMap from '../pages/FantasyMap';
import SignUpForm from '../pages/SignUpForm';
import GlobalStyle from './globalStyles';

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/fantasymap" element={<FantasyMap />} />
          <Route path="/signup" element={<SignUpForm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;