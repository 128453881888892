import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Nav = styled.nav`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-left: 2rem;
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Navbar = ({ user, onAdventuresClick }) => {
  return (
    <Nav>
      <NavList>
        <NavItem><NavLink href="#shop">Shop</NavLink></NavItem>
        <NavItem><NavLink href="#contact">Contact Us</NavLink></NavItem>
        <NavItem>
          {user ? (
            <NavLink onClick={onAdventuresClick}>
              Adventures <FontAwesomeIcon icon={faCaretDown} />
            </NavLink>
          ) : (
            <NavLink href="#adventures">
              Adventures <FontAwesomeIcon icon={faCaretDown} />
            </NavLink>
          )}
        </NavItem>
      </NavList>
    </Nav>
  );
}

export default Navbar;