import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faChartLine, faTrophy, faUsers, faBook, faPalette } from '@fortawesome/free-solid-svg-icons';
import Navbar from './NavBar';
import UserRegistration from '../components/UserRegistration';
import Login from '../components/Login';
import FantasyMap from './FantasyMap';
import { auth } from './firebase';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: white;
  font-family: 'Arial', sans-serif;
  background-image: url('./field.jpg');
  background-size: cover;
  background-position: center;
`;

const Header = styled.header`
  padding: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 1rem;
`;

const Content = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
`;

const FeatureCard = styled.div`
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1rem;
  width: 300px;
  text-align: center;
`;

const FeatureIcon = styled.span`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const CTAButton = styled.button`
  background-color: #f7b733;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f9c74f;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
`;

const Footer = styled.footer`
  text-align: center;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.6);
`;

const features = [
  {
    icon: faMap,
    title: "Global Pet Adventures",
    description: "Explore virtual pet-friendly routes worldwide.",
  },
  {
    icon: faChartLine,
    title: "Easy Activity Tracking",
    description: "Effortlessly log walks and playtime.",
  },
  {
    icon: faTrophy,
    title: "Pawsome Rewards",
    description: "Earn digital badges and real treats.",
  },
  {
    icon: faUsers,
    title: "Community Challenges",
    description: "Join global pet-walking events.",
  },
  {
    icon: faBook,
    title: "Pet Health Hub",
    description: "Get breed-specific tips and find pet spots.",
  },
  {
    icon: faPalette,
    title: "Personalized Impact",
    description: "Customize avatars, help the planet.",
  },
];

function ComingSoon() {
  const [user, setUser] = useState(null);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = (loggedInUser) => {
    setUser(loggedInUser);
    setShowLogin(false);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleRegister = (userData) => {
    console.log('User registered:', userData);
    setShowRegistration(false);
  };

  return (
    <Container>
      <Navbar user={user} onLogout={handleLogout} onAdventuresClick={() => setShowMap(true)} />
      <Header>
        <Title>Paws on the Go</Title>
        <Subtitle>Virtual Walking Challenges With Your Pet</Subtitle>
      </Header>

      <Content>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <FeatureIcon>
              <FontAwesomeIcon icon={feature.icon} />
            </FeatureIcon>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </Content>

      <CTAContainer>
        {!user ? (
          <>
            <CTAButton onClick={() => setShowRegistration(true)}>Sign Up</CTAButton>
            <CTAButton onClick={() => setShowLogin(true)}>Login</CTAButton>
          </>
        ) : (
          <CTAButton onClick={() => setShowMap(true)}>View Fantasy Map</CTAButton>
        )}
      </CTAContainer>

      {showRegistration && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={() => setShowRegistration(false)}>�</CloseButton>
            <UserRegistration onRegister={handleRegister} />
          </ModalContent>
        </Modal>
      )}

      {showLogin && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={() => setShowLogin(false)}>�</CloseButton>
            <Login onLogin={handleLogin} />
          </ModalContent>
        </Modal>
      )}

      {showMap && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={() => setShowMap(false)}>�</CloseButton>
            <FantasyMap />
          </ModalContent>
        </Modal>
      )}

      <Footer>
        <p>� 2024 Paws on the Go. All rights reserved.</p>
      </Footer>
    </Container>
  );
}

export default ComingSoon;