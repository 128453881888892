import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    /* Color Palette */
    --gold: #FFD700; /* A brighter gold color */
    --navy: #0A2342;
    --cream: #F4E9CD;
    --charcoal: #2D2D2A;
    --white: #FFFFFF;

    /* Font */
    --main-font: 'Quicksand', sans-serif;

    /* Font Sizes */
    --font-size-small: 14px;
    --font-size-medium: 16px;
    --font-size-large: 20px;
    --font-size-xlarge: 24px;

    /* Line Heights */
    --line-height-normal: 1.5;
    --line-height-large: 1.8;

    /* Spacing */
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;

    /* Border Radius */
    --border-radius: 4px;

    /* Box Shadow */
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  body {
    font-family: var(--main-font);
    font-size: var(--font-size-medium);
    line-height: var(--line-height-normal);
    color: var(--charcoal);
    background-color: var(--white);
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--navy);
  }

  a {
    color: var(--gold);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default GlobalStyle;